import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import sponsors from "./sponsors";
import Swiper from "swiper/bundle";

import collapse from '@alpinejs/collapse'
import masonry from 'alpinejs-masonry'
import focus from '@alpinejs/focus'

Alpine.plugin(collapse)
Alpine.plugin(masonry)
Alpine.plugin(focus)

window.Swiper = Swiper;
window.Alpine = Alpine;
window.htmx = htmx;

Alpine.data('Sponsors', sponsors);

if (window.htmx) {
    import('htmx-ext-head-support').then(() => {
        console.log('htmx-ext-head-support loaded');
    });
}

if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});